import React from 'react'
import SEO from '../components/SEO'
import PageTitle from '../components/PageTitle'

const ContactPage = () => {
  return (
    <div className="container">
      <SEO title="Matys carpentry - Professional and comprehensives services in the construction business." />
      <div className="mt-20 md:mt-32"></div>
      <PageTitle
        title="Contact"
        description="Feel free to reach out with any inquiry"
      />

      <div className="flex flex-col">
        <div className="flex flex-wrap justify-center">
          <div className="text-xl text-white bg-black py-3 text-center w-full max-w-xs">
            (203) 253-6358
          </div>
          <div className="text-xl text-white bg-black py-3 text-center w-full max-w-xs">
            gpmatys@gmail.com
          </div>
        </div>
      </div>

      <div className="h-16" />

      <div className="mapouter">
        <div className="gmap_canvas">
          <iframe
            width="100%"
            height="100%"
            title="find-us"
            src="https://maps.google.com/maps?q=257%20Ely%20ave%20Norwalk%2C%20CT%2006854&t=&z=15&ie=UTF8&iwloc=&output=embed"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
          />
        </div>
      </div>
    </div>
  )
}

export default ContactPage
